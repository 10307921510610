.pageSubHeader {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: white;
  font-weight: 500;
  text-align: center;
}

.item {
  color: white;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  margin: 8px 0;
}

.revealedTrackContainer {
  font-size: 16px;
}

.revealedTrackContainer .albumArt,
.placeHolderContainer .albumArt {
  display: flex;
  flex: 1 1 8%;
  align-items: center;
}

.revealedTrackContainer .albumArt img {
  width: 52px;
}

.revealedTrackContainer .artist,
.placeHolderContainer .artist {
  flex: 1 1 25%;
  padding-left: 1rem;
}

.revealedTrackContainer .songTitle,
.placeHolderContainer .songTitle {
  flex: 1 1 62%;
  padding-left: 1rem;
  font-weight: 600;
}

.revealedTrackContainer .songYear,
.placeHolderContainer .songYear {
  flex: 1 1 5%;
  padding-left: 1rem;
}

.placeHolderContainer .albumArt button {
  width: 52px;
  height: 52px;
  background-color: rgba(216, 216, 216, 0.11);
}

.placeHolderContainer .albumArt button:hover {
  background-color: rgba(216, 216, 216, 0.31);
}

.placeHolderContainer .albumArt button i {
  color: #efeaff;
}

.placeHolderBar {
  background-color: rgba(216, 216, 216, 0.11);
  width: 75%;
  height: 20px;
}

.selectedTrackContainer {
  height: 200px;
}

.selectedTrackContainer .buttons {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.selectedTrackContainer .buttons button {
  width: 98px;
  height: 68px;
}

.selectedTrackContainer .buttons button.ui.basic.inverted.selected {
  color: #fff !important;
  box-shadow: 0 0 0 2px #fff inset !important;
}

.selectedTrackContainer .buttons button div {
  line-height: 18px;
}

.selectedTrackContainer .revealButton {
  background-color: #44d7b6;
  color: white;
  width: 138px;
  height: 54px;
  font-size: 1.4em;
}

.selectedTrackContainer .revealButton:hover {
  background-color: #40bba0;
  color: white;
}

.ui.button.bigPlayTrackButton {
  background-color: #502871;
  border-radius: 10px;
  width: 150px;
  height: 150px;
  color: #efeaff;
}

.ui.button.bigPlayTrackButton:hover {
  background-color: #41215c;
}

.ui.icon.button div {
  letter-spacing: 0.93px;
  line-height: 12px;
  font-weight: lighter;
  font-size: 10px;
  margin-top: 18px;
}

#bars {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 48px;
}

.bar {
  background: #f00;
  bottom: 0;
  height: 3px;
  position: absolute;
  width: 9px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 30px;
  }
}

.bar:nth-child(1) {
  left: 3px;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 15px;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 27px;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 39px;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 51px;
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  left: 63px;
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  left: 75px;
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  left: 87px;
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  left: 99px;
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  left: 111px;
  animation-duration: 442ms;
}
.bar:nth-child(11) {
  left: 123px;
  animation-duration: 422ms;
}
.bar:nth-child(12) {
  left: 135px;
  animation-duration: 430ms;
}

.ui.divided.list > .item {
  border-top: 1px solid rgba(234, 236, 238, 0.15);
}

.trackIndex {
  width: 24px;
  margin-right: 1rem;
  text-align: right;
}
