.ui.segment.masthead {
  background-image: linear-gradient(180deg, #7c00ff 0%, #c26fff 100%);
  height: 216px;
  border: none;
  border-radius: 0;
  overflow: hidden;
}

.masthead h1.ui.header a {
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
}

.masthead img {
  width: 181px;
  position: absolute;
  bottom: -22px;
  left: 66%;
}

.masthead .button {
  background-color: #7c00ff;
  border-radius: 14.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  width: 78px;
  height: 29px;
  cursor: pointer;

  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding-top: 6px;

  position: absolute;
  top: 10px;
}

.masthead .corner {
  position: absolute;
  top: 0;

  width: 240px;
  height: 127px;
}
.masthead .corner::before {
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  content: "";
  height: 127px;
  position: absolute;
  width: 240px;
}

.masthead .corner.left {
  left: 0;
}
.masthead .corner.left::before {
  left: -160px;
  transform: skew(-63deg);
}
.masthead .corner.left .button {
  left: 10px;
}

.masthead .corner.right {
  right: 0;
}
.masthead .corner.right::before {
  right: -160px;
  transform: skew(63deg);
}
.masthead .corner.right .button {
  right: 10px;
}

.pageHeader {
  position: absolute;
  top: 85px;
  left: 0;
  width: 100%;
  text-align: center;

  color: #ffffff;
  font-size: 48px;
  line-height: 58px;
  padding-bottom: 48px;
}
