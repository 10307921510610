.ui.cards {
  margin-top: 5rem;
}

.ui.cards > .card {
  background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.ui.cards > .card > .image > img {
  border-radius: 14px;
}

.ui.cards a.card:hover,
.ui.link.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover {
  transform: scale(1.03);
  background: none;
  box-shadow: 0 1px 10px 0 #000;
  border-radius: 14px;
}

.content {
  position: absolute;
  top: 70%;
  width: 100%;

  font-size: 16px;
  font-weight: bold;

  color: white;
  text-align: center;
}
