.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  color: white;

  background-image: linear-gradient(
    to right bottom,
    #131313 0%,
    rgba(124, 0, 255, 0.2) 30%,
    rgba(124, 0, 255, 0.2) 70%,
    #131313 100%
  );
}

.splash .heading {
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 5.6px;
  line-height: 48px;
  margin-bottom: 40px;
}

.splash .ui.primary.button.login-button {
  background-color: #1db954;
  border-radius: 24px;
  font-weight: 400;
}
